
import './App.css';
import { NotFound } from './components/404/404';
import { ButtonCustomization } from './components/Dashboard/ButtonCustomization/ButtonCustomization';
import { Dashboard } from './components/Dashboard/Dashboard';
import { GameHistory } from './components/GameHistory/GameHistory';
import { GameHistoryGanamos } from './components/GameHistoryGanamos/GameHistoryGanamos';
import { GameHistoryMegafaraon } from './components/GameHistoryMegafaraon/GameHistoryMegafaraon';
import { Home } from './components/Home/Home';
import { Route, Routes } from 'react-router-dom'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/admin/:id" element={<ButtonCustomization />} />
        <Route path="/gameHistory" element={<GameHistory />} />
        <Route path="/gameHistoryMegafaraon" element={<GameHistoryMegafaraon />} />
        <Route path="/gameHistoryGanamos" element={<GameHistoryGanamos />} />

        <Route path="*" element={<NotFound />} />

      </Routes>
    </>
  )
}

export default App;
