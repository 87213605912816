import React from 'react'

export const NotFound = () => {
    return (
        <div className='App h-100 w-100'>
            <div className='mt-5  position-relative'>
                404
            </div>
        </div>
    )
}
