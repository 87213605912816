import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { getAllHistory, getAllHistoryExport, getBotUsers } from '../API';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import './historypanel.css'
import { HiOutlineDocumentDownload } from "react-icons/hi";

export const HistoryPanel = () => {
  const adminName = process.env.REACT_APP_CAJERO;
  const [historyData, setHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 30;
  const [totalPages, setTotalPages] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const [creatorName, setCreatorName] = useState('')
  const [allCreatorsNames, setAllCreatorNames] = useState([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({
    creator: '',
    user_target: '',
    startDate: '',
    endDate: '',
  })

  useEffect(() => {
    getAllCreators()
    fetchData();
  }, [adminName, currentPage]);



  const fetchData = async () => {
    setLoading(true)
    let creatorParam = filters.creator
    let targetParam = filters.user_target
    let startDate = filters.startDate
    let endDate = filters.endDate
    const data = await getAllHistory(adminName, creatorParam, targetParam, startDate, endDate, currentPage, pageSize);
    setLoading(false)
    setHistoryData(data.data || []);
    setTotalPages(data.totalPages || 0);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const getAllCreators = async () => {
    const response = await getBotUsers()
    // const usersData = response;
    if (response) {
      let allNames = response.map(user => user.name)
      setAllCreatorNames(allNames)
    }
  }

  const generatePaginationItems = () => {
    let items = [];
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 5);

    if (startPage !== 1) {
      items.push(
        <Pagination.Item key="first" onClick={() => handlePageClick(1)}>
          1
        </Pagination.Item>
      );
      items.push(<Pagination.Ellipsis key="ellipsis1" />);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageClick(number)}>
          {number}
        </Pagination.Item>,
      );
    }

    if (endPage !== totalPages) {
      items.push(<Pagination.Ellipsis key="ellipsis2" />);
      items.push(
        <Pagination.Item key="last" onClick={() => handlePageClick(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  const convertDate = (utcDateStr) => {
    const localDate = new Date(utcDateStr);
    const day = localDate.getUTCDate().toString().padStart(2, '0');
    const month = (localDate.getUTCMonth() + 1).toString().padStart(2, '0'); // getUTCMonth() retorna un valor de 0 a 11
    const year = localDate.getUTCFullYear();
    const hours = localDate.getUTCHours().toString().padStart(2, '0');
    const minutes = localDate.getUTCMinutes().toString().padStart(2, '0');
    const seconds = localDate.getUTCSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  const handleCreatorFilter = (e) => {
    // fetchData(e.target.value)
    setFilters({ ...filters, creator: e.target.value })
  }

  const handleUserTargetFilter = (e) => {
    setFilters({ ...filters, user_target: e.target.value.trim() })
  }

  const handleSearch = () => {
    fetchData()
  }


  const exportToExcel = (data, cajero) => {
    // Extracting the required columns
    const excelData = data.map(({ date, creator, description, user_target, destinationAccountName, senderName, amount }) => {
      // Dividir la descripción en palabras
      const words = description.split(' ');

      // Extraer la acción que es la primera palabra
      const action = words[0];

      // Buscar el número en la descripción ignorando el nombre que puede tener números
      const quantity = parseInt(words.find(word => !isNaN(word) && parseInt(word)));

      return {
        'Fecha': date,
        'Cajero': creator,
        'Acción': action,
        'Cantidad': amount ?? quantity,
        'Usuario': user_target,
        'Cuenta Destinatario': destinationAccountName,
        'Cuenta Remitente': senderName,
        'Detalle': description,
      };
    });

    // Creating a new workbook
    const wb = XLSX.utils.book_new();
    // Converting the data to a worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);

    ws['!cols'] = [
      { wch: 15 }, // Fecha
      { wch: 20 }, // Cajero
      { wch: 10 }, // Acción
      { wch: 10 }, // Cantidad
      { wch: 20 }, // Usuario
      { wch: 25 }, // Cuenta Destinatario
      { wch: 25 }, // Cuenta Remitente
      { wch: 50 }, // Detalle
    ];

    // Adding the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'History');

    // Writing the workbook to a file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert string to a buffer
    const buffer = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt(i) & 0xff;
    }

    // Save the file
    let today = new Date().toLocaleDateString();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const fileName = `Historial ${cajero} ${today}.xlsx`;
    if (window.navigator.msSaveOrOpenBlob) {
      // For IE
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const fetchDataToExport = async (e) => {
    if (filters.startDate && filters.endDate) {
      const startDate = new Date(filters.startDate);
      const endDate = new Date(filters.endDate);
      // Calcular la diferencia en meses entre las dos fechas
      const diffTime = Math.abs(endDate - startDate);
      const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30)); // Aproximación de un mes
      // Comprobar si la diferencia es mayor a 2 meses
      if (diffMonths > 2) {
        Swal.fire({
          icon: 'error',
          text: "La diferencia entre fecha de inicio y fecha de fin no puede ser mayor a 2 meses, en caso de necesitar exportar períodos mas largos contacta al administrador para obtener mas detalles"
        });
        return;
      }
    } else {
      Swal.fire({
        icon: 'error',
        text: "Debes seleccionar un rango de fechas para exportar (no puedes exportar un rango mayor a 2 meses)."
      });
      return;
    }

    Swal.fire({
      title: 'Exportando, por favor espera...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    setLoadingExport(true);
    let cajero = filters.creator;
    const data = await getAllHistoryExport(adminName, filters.creator, filters.user_target, filters.startDate, filters.endDate, currentPage, 30000);
    if (data?.data) { exportToExcel(data.data, cajero) }
    setLoadingExport(false)
    Swal.close();
  };

  const cleanFilters = async () => {
    setLoading(true)
    let creatorParam = ''
    let targetParam = ''
    let startDate = ''
    let endDate = ''
    setFilters({
      creator: '',
      user_target: '',
      startDate: '',
      endDate: '',
    })
    const data = await getAllHistory(adminName, creatorParam, targetParam, startDate, endDate, currentPage, pageSize);
    setLoading(false)
    setHistoryData(data.data || []);
    setTotalPages(data.totalPages || 0);
  }

  const getItemColor = (item) => {
    if (item?.description?.includes('Agregó')){
      return 'text-success'
    }
    if (item?.description?.includes('Bonificó')){
      return 'text-warning'
    }
    if (item?.description?.includes('Bajó')){
      return 'text-danger'
    }
  }

  return (
    <div className='mt-5'>
      <div className="ContainerGral">
        <div className='mb-3'>
          <div className="d-flex align-items-end justify-content-between">

            {/* <div className="d-flex flex-column align-items-start mx-3">
              <label htmlFor="creatorFilter"><small>{loadingExport ? 'Exportando...' : 'Exportar historial'}</small></label>
              <select id="creatorFilter" disabled={loadingExport} className="form-select px-5 rounded py-2 customDarkSelect" onChange={fetchDataToExport}>
                <option className='text-start' selected={false} key={'todos'} value={''}>Todos</option>
                {allCreatorsNames.map((user, index) => (
                  <option className='text-start' key={index} value={user}>{user}</option>
                ))}
              </select>
            </div> */}

            <div className="d-flex flex-column align-items-start">
              <label htmlFor="target"><small>Usuario</small></label>
              <input className='px-3 rounded border-0 customDarkInput py-2' placeholder='Buscar por jugador' name='user_target' value={filters.user_target} onChange={handleUserTargetFilter}></input>
            </div>

            <div className=" ml-3 d-flex">
              <div className="d-flex flex-column align-items-start">
                <label htmlFor="start"><small>Fecha inicio</small></label>
                <input value={filters.startDate} type="date" id="nacimiento" name="nacimiento" className="form-control customDarkDate text-white" onChange={(e) => setFilters({ ...filters, startDate: e.target.value })} />
              </div>

              <div className="d-flex flex-column align-items-start mx-2">
                <label htmlFor="end"><small>Fecha fin</small></label>
                <input value={filters.endDate} type="date" id="nacimiento" name="nacimiento" className="form-control customDarkDate text-white" onChange={(e) => setFilters({ ...filters, endDate: e.target.value })} />
              </div>
            </div>

            <div className="mx-3">
              <div className="d-flex flex-column align-items-start">
                <label htmlFor="creatorFilter"><small>Filtrar por cajero</small></label>
                <select id="creatorFilter" className="form-select customDarkSelect px-5 rounded py-2" value={filters.creator} onChange={handleCreatorFilter}>
                  <option className='text-start' key={'todos'} value={''}>Todos</option>
                  {allCreatorsNames.map((user, index) => (
                    <option className='text-start' key={index} value={user}>{user}</option>
                  ))}
                </select>
              </div>
            </div>
            <button className={`btn-green btn rounded`} onClick={handleSearch}>Buscar</button>
            <button className={`btn-dark btn rounded ml-2`} onClick={cleanFilters}>Limpiar filtros</button>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end mb-3 mt-5">
          <button className="btn btn-sm btn-skyblue d-flex align-items-center py-2" onClick={fetchDataToExport}>
            <HiOutlineDocumentDownload size={22} />
            <div className="mx-1">
              Exportar
            </div>
          </button>
        </div>

        {!loading &&
          <div>
            <Table variant="dark">
              <thead className="customHead">
                <tr className='text-secondary customHead'>
                  <th>#</th>
                  <th>Date</th>
                  <th>Usuario</th>
                  <th>Descripcion</th>
                  <th>Cuenta Remitente</th>
                  <th>Cuenta Destinatario</th>
                </tr>
              </thead>
              <tbody>
                {historyData.map((item, index) => (
                  <tr className='text-white customRow' key={item.id}>
                    <td>{(currentPage - 1) * pageSize + index + 1}</td>
                    <td className="text-secondary">{convertDate(item.date)}</td>
                    <td>{item.creator}</td>
                    <td className={getItemColor(item)}>{item.description}</td>
                    <td>{item.senderName ?? ''}</td>
                    <td>{item.destinationAccountName ?? ''}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className='d-flex justify-content-center'>
              <Pagination className="custom-pagination">{generatePaginationItems()}</Pagination>
            </div>
          </div>
        }
        {loading &&
          <div>
            Cargando...
          </div>
        }
      </div>
    </div>

  );
};
